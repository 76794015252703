import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import DropDown from "./DropDown.jsx";
import React, { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import axios from "axios";

const { Header } = Layout;

const btn_style = {
  width: 64,
  height: 64,
  color: "#fff",
  fontSize: "16px",
  marginLeft: "-50px",
}

const Headers = (props) => {
  const { collapsed, setCollapsed } = props;
  const [alerts, setalerts] = useState([]);

  const getData = async () => {
    if (!sessionStorage.getItem("token")) return;
    await axios
        .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/user/alerts`, {
            headers: {
                Authorization: sessionStorage.getItem("token"),
            },
        })
        .then((res) => {
            setalerts(res.data.data)
        })
        .catch((err) => {
            console.log(err);
        });
  };
  useEffect(() => {
    getData();
   
  }, []);

  return (
    <>
    {alerts[0]?.alertMessage?<Alert severity="warning">{alerts[0].alertMessage}</Alert>:""}
    <Header style={{height:"7vh"}} theme="dark" className="header">
      <div>
        <Button type="text" style={btn_style} 
          onClick={() => setCollapsed(!collapsed)} 
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        />
        <span>{props.name}</span>
      </div>
      <div style={{background:"red", display:"flex"}}></div>
      <DropDown first_name={props.first_name} last_name={props.last_name}/>
    </Header>
    </>
  );
};

export default Headers;
