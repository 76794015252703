import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import EditOffIcon from "@mui/icons-material/EditOff";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import FormControlLabel from "@mui/material/FormControlLabel";
import UpdateHotelPolicy from "./UpdatePolicy";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import Loading from "../Loading";
import axios from "axios";
import * as Yup from "yup";

const HotelPolicy = () => {
    const { t } = useTranslation();

    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;
    const validate = Yup.array().of(
        Yup.object().shape({
            id: Yup.number().integer().min(1).nullable().required(t("Field is required")),
            policy_id: Yup.number().integer().min(1).required(t("Field is required")),
            name: Yup.string().required(t("Field is required")),
            type: Yup.string().oneOf(["time", "boolean", "integer"]).required(t("Field is required")),
            value: Yup.mixed().when("type", {
                is: "time",
                then: Yup.string().matches(timeRegex, "Noto'g'ri vaqt formati").nullable().required(t("Field is required")),
                otherwise: Yup.mixed().when("type", {
                    is: "boolean",
                    then: Yup.boolean().nullable().required(t("Field is required")),
                    otherwise: Yup.mixed().when("type", {
                        is: "integer",
                        then: Yup.number().integer().nullable().required(t("Field is required")),
                        otherwise: Yup.mixed().notRequired(),
                    }),
                }),
            }),
        })
    );

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [data, setData] = useState([]);

    const getData = async () => {
        setLoading(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hdp-gateway/hotel/policy`, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const update = () => {
        setUpdating(true);
    };

    return (
        <div className="container mt-3">
            {loading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <Button style={{ float: "right" }} variant="outlined" onClick={update}>
                        {t("Update")}
                    </Button>

                    <h4 className="mt-4" style={{ marginBottom: "1rem" }}>
                        {t("List of policies")}
                    </h4>
                    <div className="hc-amenity no-select">
                        {data
                            .filter((e) => e.type === "time")
                            .map((amenity) => (
                                <div className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-ahj2mt-MuiTypography-root">
                                    <AccessAlarmsIcon /> {t(amenity.name)}: {amenity.value?.slice(0, 5)}
                                </div>
                            ))}
                        {data
                            .filter((e) => e.name === "Cancel booking")
                            .map((amenity) => (
                                <div className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-ahj2mt-MuiTypography-root">
                                    <EditOffIcon /> {t(amenity.name)}:{" "}
                                    {amenity.value === "null" || amenity.value === null
                                        ? t("can always cancel")
                                        : amenity.value === "0" || amenity.value === 0
                                        ? t("imposible cancel")
                                        : t(`cancel to ${amenity.value * 24} hours`)}
                                </div>
                            ))}
                    </div>
                    <div className="hc-amenity no-select">
                        {data
                            .filter((e) => e.type === "boolean")
                            .map((amenity) => (
                                <div>
                                    <FormControlLabel control={<Checkbox checked={amenity.value === "true"} />} label={t(amenity.name)} />
                                </div>
                            ))}
                    </div>
                    <UpdateHotelPolicy open={updating} setOpen={setUpdating} data={data} getData={getData} />
                </React.Fragment>
            )}
        </div>
    );
};

export default HotelPolicy;
